<template>
    <v-data-table
        :headers="headers"
        :items="numbers"
        :loading="loading"
        :options.sync="options"
        :server-items-length="totalDesserts"
        class="elevation-1"
        :items-per-page="itemsPerPage"
        :footer-props="{
                itemsPerPageOptions: [25, 50, 100, 200, 500]
        }"
        @click:row="editItem"
        dense
    >
        <template v-slot:top>
            <v-toolbar flat dense>
                <v-toolbar-title>Lista klientów</v-toolbar-title>
                <v-spacer/>
                <v-dialog v-model="dialog" max-width="700px">
                    <template v-slot:activator="{ on }">
                        <v-btn
                            color="primary"
                            tile
                            dark
                            v-on="on"
                            small
                            @click="$refs.form ? $refs.form.reset() : null">
                            <v-icon class="mr-1">mdi-phone-plus</v-icon> Dodaj numer telefonu
                        </v-btn>
                    </template>
                    <v-card>
                        <v-form
                            autocomplete="off"
                            ref="form"
                            @submit.prevent="save"
                            v-model="validSaveDataNumber"
                            lazy-validation
                        >
                            <v-card-title>
                                <span class="headline">{{ formTitle }}</span>
                            </v-card-title>

                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" sm="6" md="4" class="py-0">
                                            <v-text-field
                                                v-model="editedItem.number"
                                                label="Numer"
                                                :rules="[v => !!v || 'Pole obowiązkowe!']"
                                                :error-messages="errors.number"
                                                outlined
                                                dense
                                            />
                                        </v-col>
                                        <v-col cols="12" sm="8" class="py-0">
                                            <v-text-field
                                                v-model="editedItem.name"
                                                label="Nazwa"
                                                :error-messages="errors.name"
                                                outlined
                                                dense
                                            />
                                        </v-col>
                                        <v-col cols="12" sm="6" md="4" class="py-0">
                                            <v-text-field
                                                v-model="editedItem.short_name"
                                                label="Skrócona nazwa"
                                                :error-messages="errors.short_name"
                                                outlined
                                                dense
                                            />
                                        </v-col>
                                        <v-col cols="12" sm="6" md="4" class="py-0">
                                            <v-text-field
                                                v-model="editedItem.city"
                                                label="Miasto"
                                                :error-messages="errors.city"
                                                :rules="[v => !!v || 'Pole obowiązkowe!']"
                                                outlined
                                                dense
                                            />
                                        </v-col>
                                        <v-col cols="12" sm="6" md="4" class="py-0">
                                            <v-text-field
                                                v-model="editedItem.street"
                                                label="Adres"
                                                :error-messages="errors.street"
                                                outlined
                                                dense
                                            />
                                        </v-col>
                                        <v-col cols="12" sm="6" md="4" class="py-0">
                                            <v-text-field
                                                v-model="editedItem.no_home"
                                                label="Nr. domu"
                                                :error-messages="errors.no_home"
                                                outlined
                                                dense
                                            />
                                        </v-col>
                                        <v-col cols="12" sm="6" md="4" class="py-0">
                                            <v-text-field
                                                v-model="editedItem.no_flat"
                                                label="Nr. mieszkania"
                                                :error-messages="errors.no_flat"
                                                outlined
                                                dense
                                            />
                                        </v-col>
                                        <v-col cols="12" class="py-0">
                                            <v-textarea
                                                v-model="editedItem.info1"
                                                label="Informacje dodatkowe"
                                                :error-messages="errors.info1"
                                                outlined
                                                dense
                                            />
                                        </v-col>
                                        <v-col cols="12" class="py-0">
                                            <v-autocomplete
                                                v-model="editedItem.not_accepted_drivers"
                                                :items="listDriver"
                                                item-value="driver_number"
                                                item-text="driver_number"
                                                outlined
                                                chips
                                                clearable
                                                auto-select-first
                                                label="Wykluczeni kierowcy przez klienta"
                                                multiple
                                                dense
                                            />
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer/>
                                <v-btn color="error" tile small outlined @click="close">Anuluj</v-btn>
                                <v-btn
                                    tile
                                    small
                                    color="primary"
                                    type="submit"
                                    :disabled="!validSaveDataNumber">Zapisz</v-btn>
                            </v-card-actions>
                        </v-form>
                    </v-card>
                </v-dialog>
                <v-btn
                    to="/"
                    class="ml-3"
                    dark
                    small>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card tile class="search-card px-3 mb-3">
                <v-form
                    autocomplete="off"
                    @submit.prevent="actionSearch">
                    <v-card-subtitle class="text-uppercase">
                        Wyszukiwarka
                    </v-card-subtitle>
                    <v-card-text class="pb-0">
                        <div class="py-0">
                            <v-row>
                                <v-col cols="12" sm="8" class="py-1">
                                    <v-combobox
                                        v-model="searchName"
                                        :items="namesList"
                                        label="Nazwa"
                                        dense
                                        outlined
                                        hide-details
                                        clearable
                                        prepend-icon="mdi-pencil"
                                        aria-autocomplete="none"
                                        ref="searchName"
                                        @keyup.enter="actionSearch"
                                    />
                                </v-col>
                                <v-col cols="12" sm="4" class="py-1">
                                    <v-text-field
                                        v-model="searchNumber"
                                        prepend-icon="mdi-phone"
                                        label="Numer tel."
                                        hide-details
                                        aria-autocomplete="none"
                                        dense
                                        outlined
                                    />
                                </v-col>
                                <v-col cols="12" sm="4" class="py-1">
                                    <v-combobox
                                        v-model="searchCity"
                                        :items="cityMap"
                                        label="Miasto"
                                        dense
                                        outlined
                                        hide-details
                                        prepend-icon="mdi-city"
                                        aria-autocomplete="none"
                                        ref="searchCity"
                                        @keyup.enter="actionSearch"
                                    />
                                </v-col>
                                <v-col cols="12" sm="4" class="py-1">
                                    <v-combobox
                                        v-if="searchCity"
                                        v-model="searchStreet"
                                        :items="streetsMap[searchCity]"
                                        label="Adres"
                                        dense
                                        outlined
                                        hide-details
                                        prepend-icon="mdi-home-floor-a"
                                        aria-autocomplete="none"
                                    />
                                    <v-text-field
                                        v-else
                                        v-model="searchStreet"
                                        label="Adres"
                                        dense
                                        outlined
                                        hide-details
                                        prepend-icon="mdi-home-floor-a"
                                        aria-autocomplete="none"
                                    />
                                </v-col>
                                <v-col cols="12" sm="4" class="py-1">
                                    <v-text-field
                                        v-model="searchNoHome"
                                        prepend-icon="mdi-home-floor-1"
                                        label="Nr. domu"
                                        hide-details
                                        aria-autocomplete="none"
                                        dense
                                        outlined
                                    />
                                </v-col>
                            </v-row>
                        </div>
                    </v-card-text>
                    <v-card-actions class="mt-2">
                        <v-btn outlined @click="resetSearch" class="ml-auto">RESETUJ</v-btn>
                        <v-btn color="primary" tile type="submit"><v-icon class="mr-1">mdi-magnify</v-icon> SZUKAJ</v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </template>
        <template v-slot:[`item.id`]="{ item }">
            {{ item.id }}
        </template>
        <template v-slot:[`item.number`]="{ item }">
            <span class="phone-number">{{ formatNumberPhone(item.number) }}</span>
        </template>
        <template v-slot:[`item.name`]="{ item }">
            {{ item.short_name }}<br />
            <small>{{ item.name }}</small>
        </template>
        <template v-slot:[`item.action`]="{ item }">
            <div class="d-flex">
                <v-btn tile text small @click.stop="editItem(item)"><v-icon>mdi-pencil</v-icon></v-btn>
                <v-btn tile text small @click.stop="deleteItem(item)"><v-icon>mdi-trash-can</v-icon></v-btn>
            </div>
        </template>
    </v-data-table>
</template>

<script>
    import Vue from "vue";

    export default {
        name: "Number",
        props: {
            message: {
                type: [String, Object],
                required: false
            },
            listDriver: {
                type: Array,
                required: true
            },
            formatNumberPhone: {
                type: Function
            }
        },
        data: () => ({
            search: false,
            searchNumber: '',
            searchName: null,
            searchShortName: '',
            searchCity: null,
            searchStreet: '',
            searchNoHome: '',
            totalDesserts: 0,
            desserts: [],
            page: 0,
            itemsPerPage: 100,
            dialog: false,
            headers: [
                { text: 'ID', value: 'id', sortable: true },
                { text: 'Numer', value: 'number', sortable: true },
                { text: 'Nazwa', value: 'name', sortable: true },
                { text: 'Miasto', value: 'city', sortable: true },
                { text: 'Adres', value: 'street', sortable: true },
                { text: 'Nr. domu', value: 'no_home', sortable: true },
                { text: 'Nr. lok.', value: 'no_flat', sortable: true },
                { text: '', value: 'action', sortable: false },
            ],
            editedItem: {
                number: null,
                name: null,
                short_name: null,
                city: null,
                street: null,
                no_home: null,
                no_flat: null,
                info1: null,
                not_accepted_drivers: [],
            },
            defaultItem: {
                number: null,
                name: null,
                short_name: null,
                city: null,
                street: null,
                no_home: null,
                no_flat: null,
                info1: null,
                not_accepted_drivers: [],
            },
            options: {},
            loading: true,
            numbers: [],
            editedIndex: -1,
            errors: [],
            validSaveDataNumber: true
        }),
        computed: {
            namesList() {return this.$store.state.socket.namesMap.all},
            cityMap() {return this.$store.state.socket.cityMap},
            streetsMap() {return this.$store.state.socket.streetsMap},
            formTitle () {
                return this.editedIndex === -1 ? 'Nowy numer' : 'Edytuj numer - ' + this.editedItem.number
            },
        },
        watch: {
            message: function (val) {
                if(typeof val.type !== 'undefined' && val.type === 'data_update' && val.data_type === 'names_map') {
                    this.getDataFromApi();
                }
            },
            dialog (val) {
                val || this.close()
            },
            options: {
                handler () {
                    this.getDataFromApi()
                        .then(data => {
                            this.desserts = data.items;
                            this.totalDesserts = data.total;
                            this.itemsPerPage = data.itemsPerPage;
                            this.page = data.page;
                        })
                },
                deep: true,
            },
        },
        methods: {
            getDataFromApi () {
                this.loading = true;
                return new Promise((resolve) => {
                    let { page, itemsPerPage } = this.options;

                    if(this.search)
                        page = 0;

                    this.$http.get('p/n/l', {
                        params: {
                            per_page: itemsPerPage,
                            page: page,
                            number: this.searchNumber,
                            name: this.searchName,
                            short_name: this.searchShortName,
                            city: this.searchCity,
                            street: this.searchStreet,
                            no_home: this.searchNoHome,
                        }
                    }) // Pobieranie listy numerów
                    .then((response) => {
                        this.numbers = response.data.data;

                        let items = this.numbers;
                        const total = parseFloat(response.data.total);

                        if (itemsPerPage > 0) {
                            items = items.slice((page - 1) * itemsPerPage, page * itemsPerPage)
                        }

                        resolve({
                            items,
                            total,
                            itemsPerPage,
                            page
                        });
                    })
                    .catch(() => {
                        this.$notify({
                            group: 'foo',
                            type: 'error',
                            title: 'Wystąpił błąd.',
                            text: 'Podczas pobierania listy klientów.'
                        });
                    })
                    .finally(() => {
                        this.loading = false;
                        this.search = false;
                    });
                })
            },
            deleteItem (item) {
                confirm('Czy na pewno chcesz usunąć ten numer?') && this.$http.post('p/n/r/' + item.id)
                    .then(() => {
                        this.$notify({
                            group: 'foo',
                            type: 'success',
                            text: 'Numer został usunięty!'
                        });
                        const removeID = this.numbers.indexOf(item);
                        this.numbers.splice(removeID, 1);
                        this.close();
                    })
                    .catch(() => {
                        this.$notify({
                            group: 'foo',
                            type: 'error',
                            title: 'Wystąpił błąd.',
                            text: 'Podczas usuwania numeru.'
                        });
                    });
            },
            editItem (item) {
                this.editedIndex = item.id;
                this.$http.post('p/n/g/' + this.editedIndex) // Pobieranie danych numeru
                    .then((response) => {
                        this.editedItem = response.data;
                        this.dialog = true
                    })
                    .catch((error) => {
                        this.$notify({
                            group: 'foo',
                            type: 'error',
                            title: 'Wystąpił błąd.',
                            text: 'Podczas pobierania danych.'
                        });
                        this.errors = error.response.data.errors;
                    });
            },
            close () {
                this.dialog = false;
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1
            },
            save () {
                if(this.$refs.form.validate()) {
                    if (this.editedIndex > -1) {
                        this.$http.post('p/n/e/' + this.editedIndex, this.editedItem) // Zapis numeru
                            .then(() => {
                                this.$notify({
                                    group: 'foo',
                                    type: 'success',
                                    text: 'Dane przypisane do numeru zostały zaktualizowane!'
                                });
                                this.getDataFromApi();
                                Vue.prototype.$socket.sendObj({action:'update_4_all_names_map'});
                                this.close();
                            })
                            .catch((error) => {
                                this.$notify({
                                    group: 'foo',
                                    type: 'error',
                                    title: 'Wystąpił błąd.',
                                    text: 'Podczas aktualizacji numeru.'
                                });
                                this.errors = error.response.data.errors;
                            });
                    } else {
                        this.$http.post('p/n/c', this.editedItem) // Zapis numeru
                            .then(() => {
                                this.$notify({
                                    group: 'foo',
                                    type: 'success',
                                    text: 'Nowy numer został dodany!'
                                });
                                this.numbers.push(this.editedItem);
                                this.close();
                            })
                            .catch((error) => {
                                this.$notify({
                                    group: 'foo',
                                    type: 'error',
                                    title: 'Wystąpił błąd.',
                                    text: 'Podczas dodawania nowego numeru.'
                                });
                                this.errors = error.response.data.errors;
                            });
                    }
                } else {
                    this.$notify({
                        group: 'foo',
                        type: 'warn',
                        text: 'Popraw dane w formularzu.'
                    });
                }
            },
            actionSearch () {
                this.$refs.searchName.isMenuActive = false
                this.$refs.searchCity.isMenuActive = false

                this.search = true;
                this.getDataFromApi();
            },
            resetSearch () {
                this.searchNumber = '';
                this.searchName = '';
                this.searchShortName = '';
                this.searchCity = '';
                this.searchStreet = '';
                this.searchNoHome = '';
                this.getDataFromApi();
            }
        },
    }
</script>

<style lang="scss">
.search-card {
    background-color: #2f2f2f;
}
</style>
